@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.tiles-wrapper {
    position: relative;

    @include media-breakpoint-up(lg) {
        &::before {
            min-height: 40%;
            background-color: #f2f4f4;
            display: block;
            height: 45.5%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            content: "\00a0";
        }
    }
}

.headings {
    position: relative;
}

.coupon {
    font-size: 1.275rem;
}
